<template>
    <div style="height: 100%;display: flex;flex-direction: column;">
        <div>
            <el-row type="flex" justify="space-around">
                <van-button type="info" icon="cluster-o" @click="handleBindDevice">绑定设备</van-button>
                <van-button type="primary" icon="scan" @click="handleWXScan">扫码绑定</van-button>
            </el-row>
            <van-divider>我的设备</van-divider>
        </div>
        <div style="flex:1;overflow: auto;">
            <van-swipe-cell v-for="(item, index) in myDeviceList" :key="index">
                <el-card class="card" :body-style="{ paddingLeft: '10px', paddingTop: '10px', paddingRight: '0px', paddingBottom: '0px' }">
                    <el-row>
                        <el-col :span="7">
                            <el-image :src="'./images/' + item.deviceType + '.png'"><template #error><i class="el-icon-picture-outline"></i></template></el-image>
                        </el-col>
                        <el-col :span="17">
                            <van-cell-group>
                                <van-field label="名称" label-width="42px" :value="item.alias" readonly title-class="titleclass" input-align="right"></van-field>
                                <van-field label="序列号" label-width="45px" :value="item.serialNumber + '(' + item.remark + ')'" readonly input-align="right"></van-field>
                            </van-cell-group>
                        </el-col>
                    </el-row>
                    <el-row type="flex" justify="space-around">
                        <el-button type="text" size="medium" icon="el-icon-monitor" @click="doExperiments(item)">做试验</el-button>
                        <el-button type="text" size="medium" icon="el-icon-view" @click="viewExperiments(item)">查数据</el-button>
                    </el-row>
                </el-card>
                <template #right>
                    <van-button square type="primary" text="备注" class="swipebutton" @click="handleEditRemark(index)" />
                    <van-button square type="danger" text="解绑" class="swipebutton" @click="handleUnBindDevice(index)" />
                </template>
            </van-swipe-cell>
        </div>
        <van-dialog v-model="remarkDialogVisible" show-cancel-button @confirm="saveRemark">
            <van-field label="备注" placeholder="请输入备注" v-model="editRemarkValue">
            </van-field>
        </van-dialog>
        <van-dialog v-model="bindDeviceDialogVisible" title="绑定设备" show-cancel-button @confirm="handleBindDevice1">
            <van-field label="设备序列号" placeholder="请输入设备序列号" v-model.trim="bindDevice.serialNumber"></van-field>
            <van-field label="备注" placeholder="请输入备注" v-model.trim="bindDevice.remark"></van-field>
            <van-field label="经销商电话" placeholder="请输入经销商电话" v-model.trim="bindDevice.agentPhone"></van-field>
        </van-dialog>
    </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";

Vue.use(Vant);
export default {
    data() {
        return {
            editRemarkIndex: null,
            editRemarkValue: "",
            remarkDialogVisible: false,
            bindDeviceDialogVisible: false,
            myDeviceList: [],
            bindDevice: { serialNumber: "", remark: "", agentPhone: "" },
            wx: null,
        };
    },
    methods: {
        handleEditRemark(index) {
            let that = this;
            that.editRemarkIndex = index;
            that.editRemarkValue = this.myDeviceList[index].remark;
            that.remarkDialogVisible = true;
        },
        saveRemark() {
            let that = this;
            that.axios
                .post("Base_Device/SaveDeviceRemark", {
                    serialNumber: that.myDeviceList[that.editRemarkIndex].serialNumber,
                    remark: that.editRemarkValue,
                })
                .then(function (response) {
                    if (response.data.code == 101) {
                        that.myDeviceList[that.editRemarkIndex].remark = that.editRemarkValue;
                    }
                });
        },
        handleUnBindDevice(index) {
            let that = this;
            that.$dialog.confirm({ message: "确定要解绑吗？" }).then(function () {
                that.axios
                    .post("Base_Device/UnBindDevice", {
                        onlyInt: that.myDeviceList[index].id,
                    })
                    .then(function () {
                        that.myDeviceList.splice(index, 1);
                    });
            });
        },
        handleBindDevice() {
            let that = this;
            that.bindDeviceDialogVisible = true;
            that.bindDevice.serialNumber = "";
            that.bindDevice.remark = "";
        },
        handleBindDevice1() {
            let that = this;
            that.axios
                .post("Base_Device/BindDevice", {
                    serialNumber: that.bindDevice.serialNumber,
                    remark: that.bindDevice.remark,
                    agentPhone: that.bindDevice.agentPhone,
                })
                .then(function (response) {
                    if (response.data.code == 101) {
                        that.myDeviceList.unshift(response.data.data);
                    } else if (response.data.code == 201) {
                        that.$toast(response.data.msg);
                    }
                });
        },
        handleWXScan() {
            let that = this;
            that.wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode"],
                success: function (res) {
                    that.bindDevice.serialNumber = new URL(res.resultStr).searchParams.get("s"); // res.resultStr.substr(21);
                    that.bindDevice.remark = "扫码绑定";
                    that.bindDevice.agentPhone = "13522127811";
                    that.handleBindDevice1();
                },
            });
        },
        doExperiments(item) {
            let that = this;
            if (item.deviceType == "LYT001") {
                //留样桶，这里是来料的管理页面。直接跳转到MaterielEdit并且在跳转之前生成一条记录。它的IsDelete为true
                that.axios
                    .post("Materiel/CreateMateriel", {
                        onlyText: item.serialNumber,
                    })
                    .then(function (response) {
                        window.location.href = "WapLYT001Operate/" + response.data.data.id;
                    });
            } else {
                if (item.deviceType == "JSY001" || item.deviceType == "JSY002" || item.deviceType == "WXCW01" || item.deviceType == "WXCW03" || item.deviceType == "HWSY02" || item.deviceType == "RHD008" || item.deviceType == "LLZ001") {
                    window.location.href = "/Wap" + item.deviceType + "Operate/" + item.serialNumber;
                }
                else if (item.deviceType == "LLZ100") {
                    window.location.href = "/Wap_LLZ100_Operate/" + item.serialNumber + "/0";
                }
                else if (item.deviceType == "WXCW81") {
                    window.location.href = "/Wap_WXCW81_Operate/" + item.serialNumber;
                } else if (item.deviceType.indexOf("WXCW") > -1) {
                    window.location.href = "/WapWXCW03Operate/" + item.serialNumber;
                }
                else if (item.deviceType == "JLCT01" || item.deviceType == "JLCT02") {
                    that.$toast.fail("请使用安卓手机做试验");
                } else if (item.deviceType == "KSY001") {
                    window.location.href = "Old/WAP_KSY001/Operate?SerialNumber=" + item.serialNumber + "&OpenId=" + item.openId + "&DeviceType=KSY001";
                } else if (item.deviceType == "RSL001") {
                    location.href = "Old/WAP_RSL001/Operate?SerialNumber=" + item.serialNumber + "&OpenId=" + item.openId + "&DeviceType=RSL001";
                } else if (item.deviceType == "WDD001") {
                    location.href = "WapWDD001Operate/" + item.serialNumber;
                } else {
                    that.$toast.fail("开发中");
                }
            }
        },
        viewExperiments(item) {
            let that = this;
            if (item.deviceType == "JSY001" || item.deviceType == "LYT001" || item.deviceType == "WXCW01" || item.deviceType == "WXCW03" || item.deviceType == "RHD008") {
                window.location.href = "/Wap" + item.deviceType + "View/" + item.serialNumber;
            }
            else if (item.deviceType == "WXCW81") {
                window.location.href = "/Wap_WXCW81_Operate/" + item.serialNumber;
            }
            else if (item.deviceType.indexOf("WXCW") > -1) {
                window.location.href = "/WapWXCW03Operate/" + item.serialNumber;
            }
            else if (item.deviceType == "LLZ001") {
                //这里氯离子也转向操作页面，暂时先这么定
                window.location.href = "/Wap" + item.deviceType + "Operate/" + item.serialNumber;
            } else {
                that.$toast.fail("开发中");
            }
        },
    },
    mounted() {
        let that = this;
        that.axios.post("Base_Device/GetMyDevice", { deviceType: "" }).then(function (response) {
            that.myDeviceList = response.data.data;
        });
        that.axios.post("OfficialAccount/GetWXScanAPI", { onlyText: window.location.href }).then(function (response) {
            that.wx = require("weixin-js-sdk");
            that.wx.config({
                debug: false,
                appId: response.data.data.appId,
                timestamp: response.data.data.timestamp,
                nonceStr: response.data.data.nonceStr,
                signature: response.data.data.signature,
                jsApiList: ["scanQRCode"],
            });
            that.wx.ready(function () { });
            that.wx.error(function (err) {
                alert("error" + JSON.stringify(err));
            });
        });
    },
};
</script>

<style scoped>
.card {
    margin: 5px;
}

.swipebutton {
    height: 100%;
}
</style>